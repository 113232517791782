@import 'src/styles/variables.scss';

.right-panel {
  position: relative;
  float: left;
  width: 336px;
  height: 511px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 18px;
  background: url(#{$public-images-path}bg-controls.jpeg) no-repeat scroll left top transparent;
  background-size: 100% 100%;

  .current-lot-status {
    display: block;
    clear: both;
    padding: 28px 10px;
  }
}
