@import 'src/styles/variables.scss';

.pass-sell-lot {
  height: 43px;
  padding: 0 0 0 10px;

  .pass-btn {
    float: left;
    width: 153px;
    height: 43px;
    margin-right: 10px;
    background: url(#{$public-images-path}btn-pass.png);
    border: 0;
    cursor: pointer;
  }

  .sell-btn {
    @extend .pass-btn;

    background: url(#{$public-images-path}btn-sell.png);

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
