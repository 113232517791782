@import 'src/styles/variables.scss';

.current-lot-info {
  display: block;
  height: 139px;
  overflow: auto;
  background: url(#{$public-images-path}bg-currentlot.gif) no-repeat scroll 0 0 transparent;

  .lot-img {
    float: right;
    padding: 10px;

    img {
      width: auto;
      max-width: 100px;
      height: auto;
      max-height: 100px;
    }
  }

  .lot-number {
    margin-bottom: 15px;
    padding: 10px 0 0 10px;
    color: $dim-gray;
    font-size: 18px;
    font-style: italic;
  }

  .lot-title {
    padding: 3px 0 0 10px;
    color: $black;
    font-weight: bold;
    font-size: 16px;
    font-style: italic;
  }
}
