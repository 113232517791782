@import 'src/styles/buttons.scss';

.stop-auction-popover {
  position: absolute;
  bottom: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  min-height: 160px;
  padding: 20px 10px 10px;
  background: rgb(0 0 0 / 80%);
  border: 1px solid $tundora;

  .confirmation-content {
    bottom: 10px;
    margin: 7px 0 0;
    padding: 15px 0;
    color: $white;
    font-weight: bold;
    text-align: center;
    background: $dark-red;
    border: 1px solid $white;

    p {
      margin: 0 0 10px;
      font-size: 12px;
    }

    .btn {
      display: inline-block;
      min-width: auto;
      height: auto;
      margin: 0 2px;
      padding: 5px;
      font-size: 12px;
      line-height: normal;
      text-decoration: underline;
      border: 1px solid $white;

      &.gray {
        color: $white;
        background: $medium-light-gray;
      }
    }
  }

  .close-popover-btn {
    position: absolute;
    top: -12px;
    right: -12px;
    display: block;
    width: 30px;
    height: 30px;
    background: url(#{$public-images-path}close-btn.png);
    border: 0;
    cursor: pointer;
  }
}
