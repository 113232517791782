@import 'src/styles/mixins.scss';

.header {
  margin: 0 0 5px;

  @include clear-fix('after');

  .logo {
    float: left;

    img {
      width: 240px;
      height: 65px;
    }
  }

  .auction-detail-section {
    float: right;
    margin: 5px 0 0;

    .auction-detail {
      margin: 0;
      padding: 0;
      text-transform: capitalize;

      li {
        font-size: 14px;
        text-align: right;
        list-style: none;
      }

      .auction-name {
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
}
