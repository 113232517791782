@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.progress-bar {
  position: relative;
  display: block;
  height: 16px;
  margin: 0 0 10px;
  border: 1px solid $light-gray;

  .progress-bar-text {
    position: absolute;
    width: 100%;
    color: $dim-gray;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
  }

  .progress-bar-meter {
    display: block;
    height: 16px;
    margin: 0;
    padding: 0;
    background: url(#{$public-images-path}progress-bar.png) repeat-x scroll left top transparent;
  }
}
