@import 'src/styles/buttons.scss';

.reopen-sold-lot-popover {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  min-height: 160px;
  padding: 10px;
  background: rgb(0 0 0 / 80%);
  border: 1px solid $tundora;

  .close-popover-btn {
    position: absolute;
    top: -12px;
    right: -12px;
    display: block;
    width: 30px;
    height: 30px;
    background: url(#{$public-images-path}close-btn.png);
    border: 0;
    cursor: pointer;
  }

  &.search-view {
    position: fixed;
    top: 40px;
    bottom: auto;
    left: 3px;
    width: 310px;
    border: 1px solid rgb(255 255 255 / 10%);
  }
}

.reopen-sold-lot {
  padding: 0 0 20px;

  p {
    color: $white;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    &:first-child {
      margin-bottom: 25px;
    }
  }

  .button-list {
    padding: 0 30px;

    .btn {
      width: 100%;
      height: 34px;
      margin: 5px 0;
      line-height: 34px;
      border: 1px solid $white;
    }
  }
}
