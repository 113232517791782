@import 'src/styles/variables.scss';

.search-next-prev {
  float: right;
  width: 36px;
  margin: 3px 0 0;

  button {
    display: block;
    width: 40px;
    height: 32px;
    border: 0;
    cursor: pointer;
  }

  .search {
    background: url(#{$public-images-path}control-arrows.png) no-repeat scroll left top transparent;
  }

  .next {
    @extend .search;

    background-position: left -32px;
  }

  .prev {
    @extend .search;

    background-position: left -63px;
  }
}
