@import 'src/styles/buttons.scss';
@import 'src/styles/mixins.scss';

.lot-card {
  position: relative;
  width: 75px;
  height: 85px;
  margin: 3px;
  padding: 3px 5px;
  overflow: hidden;
  font-weight: bold;
  text-transform: capitalize;
  background-color: $bone-green;
  border: 2px solid $white;
  cursor: pointer;

  .title {
    left: 5px;
    display: block;
    width: 75px;
    height: 14px;
    overflow: hidden;
    color: $dim-gray;
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .thumb-img {
    clear: both;
    width: 72px;
    height: 57px;
    padding: 2px 0;
    line-height: 57px;
    text-align: center;
    vertical-align: middle;

    img {
      width: auto;
      max-width: 70px;
      height: auto;
      max-height: 55px;
    }
  }

  .lot-number {
    display: block;
    color: $base-color;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
  }

  .sold-lot-overlay {
    display: none;
  }

  &.lot-sold {
    .sold-lot-overlay {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      background: rgb(0 0 0 / 70%);

      strong,
      span {
        display: block;
        color: $white;
      }

      strong {
        font-size: 22px;
        text-transform: uppercase;
      }

      span {
        font-size: 10px;
      }

      .reopen-lot-hover {
        position: absolute;
        bottom: 0;
        left: 0;
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 6px 0;
        font-weight: normal;
        background: $base-color;
        border: 1px dotted $light-gray;

        a {
          color: $white;
          font-size: 10px;
          text-decoration: underline;
        }
      }

      &:hover {
        .reopen-lot-hover {
          display: flex;
        }
      }

      .reopen-lot-btn {
        color: #fff;
        font-weight: 600;
        font-size: 10px;
        text-decoration: underline;
        background: none;
        background-color: #333;
        border: none;
        cursor: pointer;
      }
    }
  }

  &.current-lot {
    border-color: $black;
  }

  .lot-hover {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 4px;
    background: rgb(0 0 0 / 80%);

    .btn {
      display: inline-block;
      min-width: 100%;
      height: 20px;
      margin: 0 0 3px;
      padding: 0;
      color: $base-color;
      font-size: 10px;
      line-height: 20px;
      background-color: $bone-green;

      &:last-child {
        margin: 0;
      }
    }
  }

  &:hover {
    .lot-hover {
      display: block;
    }
  }
}
