@import 'src/styles/variables.scss';

.ask {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-size: 24px;
  list-style: none;

  span {
    display: inline-block;
    height: 35px;
    color: $silver-gray;
    line-height: 35px;
  }

  .edit-text {
    margin: 0;
    padding: 0;

    &:hover {
      text-decoration: underline;
      background: $yellow-crayola;
      cursor: pointer;
    }
  }

  .editable-text {
    input {
      width: 94px;
      height: 24px;
      padding: 0;
      font-size: 23px;
      line-height: 24px;
      text-align: right;

      &:hover {
        background: $white;
      }
    }

    &.editable-text-md {
      input {
        width: 130px;
      }
    }

    &.editable-text-lg {
      input {
        width: 155px;
      }
    }
  }
}
