/* stylelint-disable */

/* The stylelint is disabled here to override salesforce inner components classes which
are in camelCase instead of kebab-case */

body {
  .embeddedServiceHelpButton .helpButton .uiButton {
    font-family: Arial, sans-serif;
    background-color: #005290;
  }

  .embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: 1px solid #005290;
  }
}
