@import 'src/styles/variables.scss';

.lot-estimate {
  padding: 3px 0 0 10px;
  color: $dim-gray-light;
  font-size: 11px;

  span {
    display: inline-block;
    margin: 0 1px;
  }

  .estimate-head,
  .estimate-amount {
    font-weight: bold;
  }
}
