@import 'src/styles/variables.scss';

.custom-message-popover {
  position: absolute;
  top: 100%;
  left: 8px;
  z-index: 99;
  box-sizing: border-box;
  width: 320px;
  height: 165px;
  margin: 5px 0 0;
  padding: 5px;
  overflow: hidden;
  background: $black;
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease;

  &[hidden] {
    display: block !important;
    visibility: hidden;
    opacity: 0;
  }

  .popover-header {
    input {
      box-sizing: border-box;
      width: 100%;
    }

    .send-save-message {
      display: flex;
      justify-content: space-between;
      padding: 7px 0;

      .button {
        top: 30px;
        left: 0;
        padding: 10px;
        color: $white;
        font-size: 12px;
        text-align: center;
        text-decoration: none;
        background-color: $dim-gray-light;
        border: 1px solid $light-gray;
        cursor: pointer;
      }
    }
  }

  .saved-messages {
    height: 86px;
    margin: 0;
    padding: 0;
    overflow-x: auto;

    li {
      display: flex;
      justify-content: flex-start;
      margin: 0 0 5px;

      .close-btn {
        margin: 0 0 0 7px;
        padding: 5px;
        color: $white;
        font-size: 12px;
        font-style: normal;
        text-decoration: none;
        background-color: $dim-gray-light;
        border: 1px solid $medium-light-gray;
        cursor: pointer;
      }

      .messages {
        width: 270px;
        padding: 5px;
        overflow: hidden;
        color: $white;
        font-size: 12px;
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;
        background: $picton-blue;
        border: 1px solid $white;
        cursor: pointer;
      }
    }
  }
}
