@import 'src/styles/buttons.scss';

.seller-console-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: $base-font-family;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    background: rgb(0 0 0 / 70%);
    content: '';
  }

  .modal-dialog {
    position: absolute;
    top: 20%;
    left: 50%;
    z-index: 1;
    width: min-content;
    min-width: 320px;
    transform: translate(-50%, 0);
    @media (min-width: $screen-md-min) {
      min-width: 480px;
    }

    .modal-content {
      box-sizing: border-box;
      width: 100%;
      padding: 25px 20px;
      background: $white;
      border: 1px solid rgb(0 0 0 / 20%);
      border-radius: 6px;
      box-shadow: rgb(50 50 93 / 25%) 0 6px 12px -2px, rgb(0 0 0 / 30%) 0 3px 7px -3px;
      pointer-events: auto;

      .modal-body {
        h3 {
          margin: 0 0 15px;
          font-size: 21px;
        }

        p {
          line-height: 1.3;
        }
      }

      .modal-footer {
        padding: 20px 0 0;

        button {
          margin: 0 0 0 10px;

          &:first-child {
            margin: 0;
          }
        }

        &.text-right {
          display: flex;
          justify-content: flex-end;
        }

        &.text-center {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
