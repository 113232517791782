@import 'src/styles/buttons.scss';

.search-lot-popover {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: 100%;
  min-height: 290px;
  padding: 10px 10px 15px;
  background: rgb(0 0 0 / 80%);
  border: 1px solid $tundora;

  /*
    TODO: Move these .search-bar style to a common place to remove
    the duplication with stop-auction-popover styles,
    while integrating the search popover
  */
  .search-bar {
    position: relative;
    width: 100%;
    height: 20px;
    background: url(#{$public-images-path}search-bg.png) no-repeat;
    border-radius: 25px;

    .clear-btn {
      position: absolute;
      top: 3px;
      right: 3px;
      width: 14px;
      height: 14px;
      background: url(#{$public-images-path}small-close-btn.png) no-repeat;
      border: 0;
    }

    .search-input {
      box-sizing: border-box;
      width: 100%;
      padding: 3px 29px;
      color: $medium-light-gray;
      font-weight: bold;
      background: transparent;
      border: 0;
      border-radius: 25px;
      box-shadow: none;
    }
  }

  .search-result-text {
    align-self: flex-start;
    margin: 30px 0 0;
    color: $white;
  }

  .admin-buttons {
    margin: -5px;
    margin-top: 10px;
    text-align: right;

    .btn {
      display: inline-block;
      box-sizing: content-box;
      min-width: 130px;
      height: 40px;
      margin: 5px;
      font-size: 14px;
      line-height: 40px;
      border: 1px solid rgb(255 255 255 / 70%);

      &.gray {
        color: $base-color;
      }
    }
  }

  .close-popover-btn {
    position: absolute;
    top: -12px;
    right: -12px;
    display: block;
    width: 30px;
    height: 30px;
    background: url(#{$public-images-path}close-btn.png);
    border: 0;
    cursor: pointer;
  }
}

.lot-card-search-result {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: -3px -3px 10px;
    padding: 0;
    list-style: none;

    li {
      margin: 3px;

      > div {
        background-color: rgb(0 0 0 / 80%);
        border: 2px solid $white;
        border-color: $white !important;

        span {
          color: $white !important;
        }
      }
    }
  }
}
