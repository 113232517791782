@import 'src/styles/variables.scss';

.live-message {
  height: 151px;
  margin: 7px 0 10px;
  padding: 10px;
  overflow: auto;
  background: $floral-white;
  -moz-box-shadow: 0 0 6px 0 rgb(0 0 0 / 72%) inset;
  -webkit-box-shadow: 0 0 6px 0 rgb(0 0 0 / 72%) inset;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 72%) inset;

  span {
    display: block;
    padding: 5px;
    color: $dim-gray-light;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;

    &.ask-error-message {
      color: $maroon;
    }
  }
}
