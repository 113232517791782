@import 'src/styles/variables.scss';

.floor-bid-btn {
  display: flex;
  align-items: center;
  justify-content: end;
  box-sizing: border-box;
  width: 314px;
  height: 81px;
  margin: 10px;
  padding: 0 10px;
  overflow: hidden;
  background-image: url(#{$public-images-path}floor-bid-btn.png);
  background-repeat: no-repeat;
  border: 0;
  cursor: pointer;

  &:hover {
    background-position: 0 -83px;
  }

  .title {
    float: left;
    font-weight: 500;
    font-size: 18px;
  }

  .bid-amount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    float: right;
    width: 100%;
    color: $base-color;

    .currency {
      margin-right: 10px;
      font-weight: bold;
      font-size: 14px;
      vertical-align: top;
    }

    .amount {
      font-size: 36px;
    }
  }
}
