@import 'src/styles/variables.scss';

.catalog-carousel-section {
  margin: 0;
  padding: 5px;
  overflow: hidden;
  background: $floral-white-light;
  -moz-box-shadow: 0 0 4px 0 rgb(0 0 0 / 72%) inset;
  -webkit-box-shadow: 0 0 4px 0 rgb(0 0 0 / 72%) inset;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 72%) inset;

  .catalog-carousel {
    float: left;
    width: 285px;

    ul {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
      }
    }
  }
}
