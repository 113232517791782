@import 'src/styles/variables.scss';
@import '../floor-bid-btn/floor-bid-btn.module.scss';

.internet-bid-btn {
  @extend .floor-bid-btn;

  background-image: url(#{$public-images-path}internet-bid-btn.png);
  background-position: 0 -168px;
  border: none;

  &:hover {
    background-position: 0 -168px;
  }

  .bid-amount {
    position: relative;
    display: none;
    color: $white;

    .currency-amount-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .bid-paddle {
    float: right;
    font-size: 12px;
    line-height: 12px;
  }

  &.on {
    background-position: 0 -83px;

    &:hover {
      background-position: 0 1px;
    }

    .bid-amount {
      display: block;
    }
  }
}
