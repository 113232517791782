@import '../ask/ask.module.scss';

.increment {
  @extend .ask;

  position: relative;

  .reset-increment {
    position: absolute;
    top: 2px;
    left: 115px;
    display: none;
    height: 13px;
    margin: 4px 0 0;
    padding: 4px 7px;
    color: $white;
    font-size: 11px;
    line-height: 12px;
    text-transform: capitalize;
    text-decoration: underline;
    background: $dim-gray-light;
    cursor: pointer;
  }

  &:hover {
    .reset-increment {
      display: block;
    }
  }
}
