// Base variables
$base-font-family: helvetica, arial, sans-serif;
$public-images-path: '/../public/images/';

// brand colors
$base-color: #333;
$black: #000;
$bone-green: #d0d0b4;
$gray: #7f7f7f;
$dim-gray-light: #666;
$tundora: #444;
$medium-light-gray: #999;
$dim-gray: #6c685f;
$floral-white-light: #ece5d3;
$floral-white: #fff9ea;
$light-gray: #ccc;
$silver-gray: #777;
$white: #fff;
$yellow-crayola: #fbf39c;
$yellow: #ff0;
$blue: #00f;
$picton-blue: #69c;
$dark-lime-green: #468847;
$light-grayish-green: #d6e9c6;
$darkest-red: #551313;
$light-red: #ec8787;
$darkest-yellow: #7c6d1f;
$pale-yellow: #f8ecad;
$maroon: #900;

// Buttons Variables
$dark-gold: #b69523;
$dark-green: #004e00;
$dark-red: #970000;
$dark-yellow: #bebe04;
$golden-brown: #cfcf00;
$irish-green: #0a9b0a;
$medium-brown: #725e1a;
$napier-green: #008700;
$orange-yellow: #cca932;
$red-brown: #aa2323;
$rosewood: #5a0d0d;

// Media Queries
$screen-md-min: 992px;
