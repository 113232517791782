@import 'src/styles/variables.scss';

.loading-overlay-holder {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: Arial;
  background: none repeat scroll 0 0 $white;
  opacity: 0.95;

  .loading-overlay-body {
    width: 780px;
    padding: 160px 0 0;
    text-align: center;

    .error-text {
      color: red;
    }

    h2 {
      margin: 0 0 25px;
      color: $base-color;
      font-weight: bold;
      font-size: 34px;
    }

    h3 {
      margin: 0 0 30px;
      padding: 0;
      color: $gray;
      font-weight: normal;
      font-size: 22px;
    }

    p {
      width: 625px;
      margin: 0 auto;
      padding: 0 0 30px;
      color: $gray;
      font-weight: normal;
      font-size: 14px;

      a {
        display: inline-block;
        margin: 0 0 0 4px;
        color: $blue;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .spinner-holder {
      display: inline-block;
      margin: 0 0 52px;
    }
  }
}

.spinner {
  width: 40px;
  height: 40px;
  margin: auto;
  border: solid 4px rgb(0 0 0 / 20%);
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
