@import url('https://www.invaluable.com/styles/fonts/638382/AC410E23F58137969.css');
@import 'variables.scss';

html,
body {
  margin: 0;
  padding: 0;
  background: url('../../public/images/bg-blue.jpeg') repeat scroll left top;
}

a {
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
