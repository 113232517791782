@import 'src/styles/buttons.scss';

.spinner {
  position: absolute;
  top: 15px;
  right: 15px;
  display: none;
  width: 15px;
  height: 15px;
  margin: auto;
  border: solid 2px rgb(0 0 0 / 20%);
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.seller-console-alert {
  position: absolute;
  top: 1em;
  left: 50%;
  width: 38em;
  padding: 1em;
  font-family: $base-font-family;
  text-align: center;
  border-radius: 4px;
  transform: translate(-50%, 0%);
  transition: all 0.3s ease;

  p {
    margin: 0;
  }

  &.success {
    color: $dark-lime-green;
    background: $light-grayish-green;
    visibility: visible;
    opacity: 1;

    &.hidden {
      visibility: hidden;
      opacity: 0;
    }
  }

  &.danger {
    color: $darkest-red;
    background: $light-red;
  }

  &.warning {
    color: $darkest-yellow;
    background: $pale-yellow;

    .spinner {
      display: block;
    }
  }
}
