@import 'src/styles/buttons.scss';

.start-stop-pause-buttons {
  margin: 0 -5px 10px;

  .btn {
    width: 47%;
    margin: 0 5px;
  }
}
