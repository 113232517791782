@import '../ask-increment/ask/ask.module.scss';

.current-bid {
  @extend .ask;

  font-weight: bold;
  font-size: 24px;
}

.high-bidder {
  @extend .ask;

  font-weight: bold;
  font-size: 14px;
}
