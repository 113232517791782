@import 'src/styles/buttons.scss';

.send-messages-revert-bid {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 8px;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    min-width: 100px;
    height: 40px;
    padding: 0;
    font-size: 12px;
    line-height: normal;
    text-transform: uppercase;
    box-shadow: inset 0 -79px 33px -59px rgb(0 0 0 / 30%);

    &.secondary-yellow-btn {
      color: $base-color;
    }
  }
}
