@import './variables.scss';

.btn {
  min-width: 143px;
  height: 32px;
  padding: 0 10px;
  color: $white;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  border: 0;
  cursor: pointer;

  &.red {
    background-color: $dark-red;
    box-shadow: 0 -6px 20px -10 $rosewood inset;

    &:hover {
      background-color: $red-brown;
    }
  }

  &.green {
    background-color: $napier-green;
    box-shadow: 0 -6px 20px -10 $dark-green inset;

    &:hover {
      background-color: $irish-green;
    }
  }

  &.yellow {
    background-color: $dark-gold;
    box-shadow: 0 -6px 20px -10 $medium-brown inset;

    &:hover {
      background-color: $orange-yellow;
    }
  }

  &.secondary-yellow {
    color: $base-color;
    background-color: $golden-brown;
    box-shadow: 0 -6px 20px -10 $medium-brown inset;

    &:hover {
      background-color: $dark-yellow;
    }
  }

  &.gray {
    color: $black;
    background: $light-gray;
    box-shadow: 0 -6px 20px -10px $dim-gray inset;
  }

  &.gray-dark {
    color: $white;
    background: $dim-gray-light;
  }

  &.link {
    color: blue;
    font-weight: normal;
    background: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &:hover {
    text-decoration: none;
    box-shadow: none;
  }

  &.full-width {
    @extend .green;

    box-sizing: border-box;
    width: 97% !important;
  }
}
