@import 'src/styles/variables.scss';
@import 'src/styles/mixins.scss';

.main-container {
  box-sizing: border-box;
  width: 780px;
  margin: 0 auto;
  padding: 0 50px 20px 40px;
  font-family: $base-font-family;
  background-color: $white;
  @include clear-fix('after');
}

div[hidden] {
  display: none !important;
}
